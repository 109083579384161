import { tr } from "date-fns/locale";

export const isDate = (obj: any) => {
  return obj instanceof Date && Object.prototype.toString.call(obj) === "[object Date]";
};

export const getDateStringMMDDYYYY = (date: Date | string | undefined): string => {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    try {
      date = new Date(date);
    } catch (e) {
      return getDateStringMMDDYYYY(new Date());
    }
  }
  if (!isDate(date)) {
    return getDateStringMMDDYYYY(new Date());
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getDateFromMongoDBObjectId = (objectId: string | any): Date => {
  const timestamp = objectId.toString().substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000);
};

export const getTimestampSeconds = (): number => {
  return Math.floor(new Date().getTime() / 1000);
};

export const isRecent = (timestamp: number, interval = 1000 * 60 * 30) => {
  const timeDiff = Date.now() - timestamp;
  return timeDiff < interval;
};

//timestamp is in past 30 minutes
export const isRecentSeconds = (timestamp: number, interval = 60 * 30) => {
  return isRecent(timestamp * 1000, interval * 1000);
};
